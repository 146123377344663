<template>
  <div class="contactUs">
    <div class="contact-card">
      <div class="contact-card-notice">
        我们重视您对服务的每一声问询，提供服务查询、咨询，信息反馈、投诉及建议等服务内容。如有需要您可以联系我们。
      </div>
      <div class="phone-number">
        <span>热线电话：</span>
        <a>88585518</a>
      </div>
      <!-- <div class="qrCode">
        <div class="tips">
          <div>微信联系：请扫下面的二维码</div>
          <div>长按识别二维码</div>
        </div>
        <div class="qrcode-img">
          <img src="./img/qrcode.png" alt="" />
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  name: "contactUs",
  components: {},
  props: {},
  data() {
    return {};
  },
  created() {},
  mounted() {},
  methods: {},
};
</script>

<style scoped lang="less">
.contactUs {
  padding: 36px 10px;
  background: #f3f8fc;
  height: 100vh;
  box-sizing: border-box;
  .contact-card {
    height: 100%;
    background: url("./img/bg.png") no-repeat;
    background-size: 100% 100%;
    padding: 80px 56px;
    // background-position-y: 36px;
    box-sizing: border-box;
    .contact-card-notice {
      height: 144px;
      font-size: 28px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.85);
      line-height: 48px;
    }
    .phone-number {
      margin: 150px 0;
      text-align: center;
      font-size: 36px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.85);
      line-height: 48px;
      a {
        color: #2e7dff;
        text-decoration: underline;
      }
    }
    .qrCode {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      .tips {
        margin-bottom: 14px;
        div {
          &:first-child {
            font-size: 28px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: rgba(0, 0, 0, 0.85);
            line-height: 48px;
            text-align: center;
          }
          &:last-child {
            font-size: 26px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: rgba(0, 0, 0, 0.5);
            line-height: 48px;
            text-align: center;
          }
        }
      }
      .qrcode-img {
        width: 320px;
        height: 320px;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
</style>
